import { Link } from "react-router-dom";

import logo from './assets/logo192.png';

export default function Header() {

  return (
    <header className="flex-shrink-0 p-2 d-flex justify-content-between align-items-center">
      <div className="d-flex gap-3 align-items-center">
      <Link to="/"><img width="32" className="d-block" src={logo} alt="Lyceum Games logo" /></Link> Lyceum Games
      </div>
      {/* <nav className="d-flex gap-3">
        <button className="btn">About</button>
        <button className="btn">Blog</button>
        <button className="btn">Store</button>
      </nav> */}
      <div/>
    </header>
  )
}
