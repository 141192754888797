import logo from './assets/logo192.png';


function App() {
  return (
    <div className="d-flex flex-column min-vh-100">
      {/* <Header /> */}
      <main
        className="flex-grow-1 d-flex p-3 border-box flex-column justify-content-center align-items-center text-align-center position-relative"
        style={{
          maxWidth: 768,
          margin: 'auto',
        }}
      >
        <img className="d-block pixelated mb-5 w-100 mx-7" src={logo} style={{ maxHeight: 250, maxWidth: '33%' }} alt="Lyceum Games" />
        <h1 className="d1">Lyceum Games</h1>
        <h2 className="mb-5">Purveyors of fine half-finished indie games since 2015.</h2>

        <a href="/myth"><button className="btn">Myth</button></a>
      </main>
      <footer className="text-gray small flex-shrink-1 d-flex justify-content-center">
      &copy; {(new Date()).getFullYear()} Lyceum Games
    </footer>
      </div>
  );
}

export default App;
