import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import { useState } from 'react';

import cave from './assets/cave.png';
import darkness from './assets/darkness.png';
import fire from './assets/fire.png';
import sheet from './assets/sheet.png';
import shop from './assets/shop.png';
import Header from './Header';

export default function Myth() {

  const images = [
    cave,
    sheet,
    fire,
    darkness,
    shop
  ]

  const [index, setIndex] = useState<number>(0);

  const handleLeft = () => {
    setIndex(index => {
      if (index === 0) {
        return images.length - 1;
      }
      return index - 1;
    })
  }

  const handleRight = () => {
    setIndex(index => {
      if (index === images.length - 1) {
        return 0;
      }
      return index + 1;
    })
  }

  return (
    <div className="d-flex flex-column min-vh-100">
    <Header />
    <main
      className="flex-grow-1 d-flex p-3 border-box flex-column justify-content-center align-items-center text-align-center position-relative"
      style={{
        maxWidth: 1024,
        margin: 'auto',
      }}
    >
      <h1 className="d0">Myth</h1>

      <p className="large mb-5 mx-6" style={{ lineHeight: '1.8rem' }}>
        A classic, open-world roleplaying adventure in the style of early Ultima and Final Fantasy games.  Explore the world of Teriam with a party of stalwart companions.  Unravel the secrets of the Aspects, the mythical avatars of Teriam's ancient, forgotten gods.
      </p>

      <div className="d-flex gap-3 align-items-center mb-3">
        <button onClick={handleLeft} className="btn">
          <ChevronLeftIcon className="d-block" width="24" height="24" />
        </button>
        <div className="well p-3">
          <img style={{ maxWidth: 800 }} className="d-block w-100 pixelated" src={images[index]} alt="Screenshot" />
        </div>
        <button onClick={handleRight} className="btn">
          <ChevronRightIcon className="d-block" width="24" height="24" />
        </button>
      </div>
      <div>
        {index + 1} / {images.length}
      </div>
    </main>
    <footer className="text-gray small flex-shrink-1 d-flex justify-content-center">
      &copy; {(new Date()).getFullYear()} Lyceum Games
    </footer>
    </div>
  );
}
